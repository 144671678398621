function bodyset(gridClasses,activeItem) {
	$('body').removeClass().addClass(gridClasses);
	$('.grid-list li').removeClass('active');
	if (activeItem != null) {
		$(activeItem).addClass('active');
	}
}


$('#grid-over').click(function(){
	var thisItem = $(this).closest('li');
	bodyset('grid-overlay', thisItem);
});

$('#grid-under').click(function(){
	var thisItem = $(this).closest('li');
	bodyset('grid-underlay',thisItem);
});

$('#grid-base').click(function(){
	var thisItem = $(this).closest('li');
	bodyset('grid-baseline',thisItem);
});

$('#grid-off').click(function(){
	bodyset(null);
});



$('#retro-button').click(function(e){
    $('body').addClass('design-01');
    $('.btn-primary').addClass('btn-info');
})
$('#current-button').click(function(e){
    $('body').removeClass('design-01');
    $('.btn-primary').removeClass('btn-info');
})
