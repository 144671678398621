   /* $("#signature").bind("change", function(e){ alert("called");addStroke(); }) */
   
   function addStroke()
   {
        //alert("count=" + count);
        //count = document.getElementById("main_form:sig_stroke_count").value;
        //count = count+1;
        //document.getElementById("main_form:sig_stroke_count").value = count;
   
   }
   
   function escapeBase64(sigData)
   {
		sigData = sigData.replace(/\+/g, "_pl_"); 
		//alert("1:" + sigData);

		sigData = sigData.replace(/=/g, "_eq_"); 
		//alert("2:" + sigData);

		sigData = sigData.replace(/\//g, "_sl_"); 
		// alert("3:" + sigData);

		return sigData;
	}
 
   
   function resetStrokeCount()
   {
       document.getElementById("main_form:sig_stroke_count").value = 0;
   }
  
  function processSigData()
  {

	  $sigdiv = $("#signature");
	  
	  var sigData = $sigdiv.jSignature("getData");
	  sigData = escapeBase64(sigData);
	  
      document.getElementById("main_form:sig_data").value = sigData;
      // line below extracts the panel data as a base30 set of vector points
      // the number it generates indicates how many points have been added to the panel
      // since the last reload
      // it's how we tell if the panel is empty or not
      count = $sigdiv.jSignature('getData','base30')[1].length;
      // alert("initial count=" + count);
      // oddly enough this method only returns the points added since the last reload
      // so we have to add this to any points that were in previous submits
      count = count + parseInt(document.getElementById("main_form:sig_stroke_count").value);
      //alert("final count=" + count);
      document.getElementById("main_form:sig_stroke_count").value = count;
      return true;
      
  }
  
  /* If there is a signature panel then this will set it up */
   
  function setupSignature()
  {
       // set up the correct sig type
       changeSigType(document.getElementById('main_form:sig_type').value);  
       pngData = document.getElementById("main_form:sig_data").value;
      
       if (pngData.length > 0)
       {
            $sigdiv = $("#signature");
            $sigdiv.jSignature("setData",pngData);
       }
  
  
  }
  


	var sigInit = false;
	
	
	function changeSigType(sigType)
	{

		clr();
		document.getElementById("placeholder").style.display = "none";

		document.getElementById('main_form:sig_type').value = sigType;

		if (sigType == "SIG_TYPED")
		{
			// Begin JG
			$('#btn-type').addClass('selected');
			$('#btn-draw').removeClass('selected');
			// End JG

			document.getElementById("typeit").style.display = "inline";
			document.getElementById("signature").style.display = "none";
		}
		else
		{
			// SIG_DRAWN
			document.getElementById("typeit").style.display = "none";
			document.getElementById("signature").style.display = "inline";

			// Begin JG
			$('#btn-draw').addClass('selected');
			$('#btn-type').removeClass('selected');
			// End JG

			if (sigInit == false)
			{ 
				$("#signature").jSignature();
				sigInit = true;
			}
		}
	}

	$("#switcher .btn").click(function(e) {
		e.preventDefault();
	    clr();

	    var sig_type_field = $('input[id$="sig_type"]');
	    var type_panel = $('#typeit');
	    var sign_panel = $('#signature_holder');
	    var place = $('#placeholder')

		switch($(this).attr('id')) {
		  case 'btn-type':
		  	sig_type_field.val('SIG_TYPED');
		    updateSigDisplay($(this), type_panel);
		    break;
		  case 'btn-draw':
		  	sig_type_field.val('SIG_DRAWN');
		    updateSigDisplay($(this), sign_panel);
			if (sigInit == false)
			{ 
				$("#signature").jSignature();
				sigInit = true;
			}
		    break;
		  case 'btn-clear':
		  	sig_type_field.val(null);
		    updateSigDisplay($(this), place);
		  	break;
	  	  default:
		}
	});
	
	function updateSigDisplay(btn,panel)
	{
		$("#switcher .btn").not(btn).removeClass('selected');
		btn.addClass('selected');
		$('.signature-container > div').not(panel).addClass('hidden');
		panel.removeAttr('class');
	}




    /* this function is called by the page when someone changes the typed name.
     * We use an asynchronous timer here so that the user is not stuck waiting for a slow
     * server to respond to each keystroke */
	function updateSigGraphic()
	{
    	// we don't what these to stack up
    	// so if there is one pending then we clear it out
    	if (timeout != undefined)
    	{
    		window.clearTimeout(timeout);
    	}
    	timeout = window.setTimeout(function(){updateSigGraphic2();},20);
    	

	}
    
   var timeout;

    /* This function is the one the timer executes */
    function updateSigGraphic2()
	{
	    val = document.getElementById("main_form:sig_name").value;
	    
	    img = document.getElementById("sig_graphic");
	    //alert(img.src);
	    
	    img.src = "/lmportal/signature?name=" + val;
	   // alert(img.tagName);

	    img.style.display = "inline";
	    //alert(img.src);
	}
	
	
	function clr()
	{
	    resetStrokeCount();
	    $sigdiv = $("#signature");

	    
	    try
	    {
	    	$sigdiv.jSignature("reset");
	    }
	    catch(e)
	    {
	    	// if the panel isn't showing this won't work
	    }
	    
	    
	    document.getElementById("main_form:sig_name").value = "";
	    updateSigGraphic();
	
	}


var sig_error = $('#sign-here .inline_error').length > 0;

$('.switcher .btn').click(function(e) {
    var sigMethod = e.target.id;

    if ($('#sign-here .inline_error').length > 0) {
        clearSignature();
    }

    if (sigMethod == 'btn-type') {
        $('.type-field-holder').attr('data-kbd', true);
    } else {
        $('.type-field-holder').attr('data-kbd', false);
    }
});

if ($('#sign-here .inline_error').length != 0) {
    sig_error = true;

    $('#sign-here')
        .has('.inline_error')
        .find('.instruct')
        .addClass('text-danger');
}

$('input[id$="sig_name"]').focus(function() {
    clearSignature();
});

function clearSignature() {
    sig_error = false;

    $('#sign-here').find('.instruct').removeClass('text-danger');
    $('#sign-here').find('.alert').remove();
}
