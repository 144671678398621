// JavaScript Document

smDev = false;

function isSmDev() {
    var vpWidth = $(window).width();
    var vpHeight = $(window).height();

    if ((vpWidth < 768) && (vpHeight < 651)) {
        smDev = true;
    } else {
        smDev = false;
    };

    return smDev;
}


// To prevent hammering, this processing button 
// is swapped in for the Submit or Continue button
// upon submission

// Create the loading icon
var processing_icon = $('<i/> ', {
                            class       : 'fa fa-spinner fa-pulse fa-fw'
                        })

// Create the processing button
var processing_button = $('<button/>', {
                            text        : ' Submitting',
                            id          : 'btn-processing',
                            'data-state': 'submission',
                            class       : 'btn btn-primary',
                            'disabled'  : true
                        }).prepend(processing_icon);


$(document).ready(function() {

    // Copyright date
    var thisYear = new Date().getFullYear();
    $('footer ul.copyright li:first-child').prepend('&#169;' + thisYear + ' ');

    // Creates the PDF Icon image preceding the PDF Links; Sets target to a new window
    $('a.pdflink').prepend('<img src="/lmpublic/img/pdficon_small.gif" width="17" height="17" alt="PDF" />').attr('target', "_blank");

    // For full-page tables that contain no data (empty)
    // Removes "page-table" class from body
    if ($('body.page-table div.no-files').length == 1) {
        $('body').removeClass('page-table');
    }


    // Provides "lightbox style" background for
    // small screen navbar when extended
    $('#navbar').on('show.bs.collapse', function () {
        $('#lightbox-bg').fadeIn('fast');
    }).on('hide.bs.collapse', function () {
        $('#lightbox-bg').fadeOut('fast');
    });

    $('#lightbox-bg').click(function() {
       $('#navbar').collapse('hide');
    });


 /*
    Mobile Footer
    This script is strictly for the slide-ups on the footer navigation.
    
    "show.bs" sets the maximum height of the outer container, so it doesn't
    slide up past the top of the window.

    "shown.bs" determines if the content is too high for the container,
    and applies a scrollbar to the container if it is. (The content is
    unavailable to be measured in the previous "show.bs" function)

    "hidden.bs" removes the scrollbar once the content is hidden. This is 
    in case the user changes their screen size to a different setting, 
    where scrolling may not be necesarry.
*/

    $('#footer-accordion').on('show.bs.collapse', function(event) {

        var viewportHeight = $(window).height();
        var accordHeight = viewportHeight;
        var contentHeight = accordHeight - 63;

        $('#footer-accordion').css('max-height', accordHeight)
            .find('.panel[role="tab"]').css('max-height', contentHeight);

        }).on('shown.bs.collapse', function() {

            var myPanel = $('#footer-accordion .panel').has('.panel-collapse.in');
            var pHeight = myPanel.height() + 1;
            var pScroll = myPanel[0].scrollHeight;

            if (pHeight < pScroll) {
                myPanel.addClass('scrollable')
            };

        }).on('hidden.bs.collapse', function() {
            $(this).find('.panel.scrollable').removeClass('scrollable');
    });





    // This Close button is for the Mobile Footer's panels
    $('#footer-accordion button.close').click(function() {
        $(this).closest('.collapse.in').collapse('hide');
    });


/*
    Font Awesome Alert Boxes
    Pure vanity - this one adds a visual style to alert boxes with a header or leading
    paragraph that contains one of the Font Awesome icons. If the first element's 
    (h4, h5, or p - only) first character is an .fa icon, subsequent elements will be
    indented to match.
*/

    $('.alert').each(function() {
        leadIconAlert($(this));
    });


    var printPointer = '';

    // Operations for a re-usable Modal Window
    $('#globalModal').on('show.bs.modal', function(event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var modTitle = button.data('newmodaltitle');
        var modContent = button.data('pagecontent');

        printPointer = modContent;
        // Extract info from data-* attributes
        //  If necessary, you could initiate an AJAX request here 
        //  (and then do the updating in a callback).
        //  Update the modal's content. We'll use jQuery here, 
        //  but you could use a data binding library or other methods instead.

        var modal = $(this)

        // Create the modal-title as an H1
        $('<h1/>', {
            'id': 'modalBoxLabel',
            'class': 'modal-title'
        }).appendTo('#globalModal .modal-header');

        modal.find('.modal-title').text(modTitle); // Sets Modal Title
        modal.find('.modal-body').load(modContent); // Fills in Modal Body content

    }).on('hidden.bs.modal', function (event) {
        var modal = $(this); // Removes Header and all content
        modal.find('.modal-title').remove();
        modal.find('.modal-body').text('');
    })



    // Print only the content of Bootstrap Modal windows when modals are active
    $('.modal').on('shown.bs.modal', function() {

        var myModalID= $(this).attr('id');

        $('body').addClass('print-modal-active');

        // Get the text from the modal title (in the header)
        var printHead = $(this).find('.modal-title').text();

        var modFooter = $('footer').clone();
        modFooter.find('#footer-content > *').not('.container').remove();
        modFooter.find('.container h2, .container .ecs-seal').remove();

        // Define shorthand utility method
        $.extend({
            el: function(el, props) {
                var $el = $(document.createElement(el));
                $el.attr(props);
                return $el;
            }
        });

        // Create the #printSection div
        $('<div/>', {
            id: 'printSection'
        }).appendTo('body');

        $('#printSection').append(
            $.el('div', {'class': 'page-header'}).append(
                $.el('h1', {
                    'class': 'Foo'
                }).append(printHead)
            )
        ).append(
            $.el('header', {'role': 'banner'}).append(
                $.el('div', {'class': 'navbar'}).append(
                    $.el('div', {'class': 'modal-brand'}).append(
                        $.el('img', {
                            src: '../lmpublic/img/ml_logos.png'
                        })
                    )
                )
            )
        ).append(
                $.el('div', {
                    'id': 'print-content'
                })
            ).append(modFooter);

        if (myModalID == 'globalModal') {
            $('#print-content').load(printPointer)
        } else {
            printModalLoad();
        }



        // $('#print-material').load(printPointer);

        // $('#modal-publish').load(printPointer);

        $('.modal-print').bind('click',printWin)
    })

    $('.modal').on('hide.bs.modal', function() {

        $(this).find('.modal-body').removeAttr('id')
        $('body').removeClass('print-modal-active');
        $('#printSection').remove();

        // Function must be unbound when modal is hidden
        // or it will be re-assigned on every "click"
        $('.modal-print').unbind('click',printWin);
    })


    function printWin() {
        window.print();
    }

    $('.table-controls .btn-group').each(function(){
        if ($(this).is(':empty')) {
            $(this).remove(); 
        }
    });

    $('.h5-date input[type="text"]').attr('type', 'date');
    $('.h5-tel input[type="text"]').attr('type', 'tel');
    $('.h5-email input[type="text"]').attr('type', 'email');


    // Provides headers for multi-column tables in XS screens

    // var multiTableHeads = [];

    // $('.table-multicol').each(function(i){
    //     var headers = [];
    //     $(this).find('thead tr th').each(function(i){
    //         headers[i] = $(this).text().trim();
    //     })
    //     multiTableHeads[i] = headers;

    //     $(this).find('tbody tr').each(function(){
    //         $(this).find('td').each(function(i){
    //             if (i > 0) {
    //                 $(this).before('<div class="micro-th">'+ headers[i] +' </div>');
    //             }
    //         });
    //     });
    // })


    $('.table-multicol').each(function(){
        var tblID = $(this).attr('id');

        $(this).find('thead tr:last-child th').each(function(i){
            if (i != 0) {
                var headText = $(this).text().trim();
                var j = i + 1;
                $('style:not([media="print"])')
                    .append('#' + tblID + ' tbody tr td:nth-child(' + j + ')::before { content: "' + headText + '"}');
            }
        });
    })




    // Data Set snippets to add
    // $('.data-series dl.data-set[class*="horizontal"] dd:last-child')
    //     .addClass('last-def')
    //     .prev('dt').addClass('last-term');


    $('.table-multicol tr td:last-child').addClass('last-point').prev('.micro-th').addClass('last-label');


    // BOOTSTRAP - Jumbotron fix
    // In cases where Jumbotron contains a <small> tag

    $('.jumbotron h1').has('small').addClass('hasSmall');




    // FORMS - Hide sticky navigation
    // This is to manage screen real estate on small
    // devices (mini-tablets and phones)

        var activeKbd = false;


        $('.path-forward').closest('form').each(function(){
            $(this).find('input').not(':submit').keypress(function(event) {
                if (event.which == 13) {
                    event.preventDefault();
                    $('.btn-primary').trigger('click');
                }
            });
        })


        $('input.form-control')
            .focus( function(){
                var handheld = isSmDev();
                if ((!activeKbd) && (handheld)) {
                    navSlideOff();
                }
            }).blur(function(){
                setTimeout(function(){
                    findFocus();
                }, 200)
        });


        function findFocus(){
            var focusCount =  $('input.form-control:focus').length;

            if (focusCount == 0) {
                navSlideOn();
            }
        }

        function navSlideOn() {
            $('body').removeAttr('style');
            $('.navbar-fixed-top').animate({
                    'top': 0
                }, 'fast');
            $('.navbar-fixed-bottom').animate({
                    'bottom': 0
                }, 'fast');
            activeKbd = false;
        }

        function navSlideOff() {
            var navLeadHeight = $('.navbar-fixed-top').outerHeight();
            var navTrailHeight = $('.navbar-fixed-bottom').outerHeight();

            $('body').animate({
                'paddingTop': 0
                }, 'fast')
            $('.navbar-fixed-top').animate({
                    'top': -navLeadHeight
                }, 'fast');
            $('.navbar-fixed-bottom').animate({
                    'bottom': -navTrailHeight
                }, 'fast');
            activeKbd = true;
        }




    // FORMS - Show/Hide password
    var     pwElems     = $('input[type="password"], .toggle-password'),
            pwPage      = pwElems.closest('body');
            pwFocused   = false; // pwElems.is(':focus')

    $('.toggle-password').click(function() {
        passShow($(this));
    });

    pwPage.find('a, input, textarea, button').focusin(function(e) {
        var myFocus = $(e.target).closest('.pw-reveal');

        if (!$(e.target).is(pwElems)) {
            hideControls($('.pw-reveal'));
        } else {
            hideControls($('.pw-reveal').not(myFocus));
            myFocus.addClass('pw-focused');
        }
    });

    function hideControls(pwGroups) {
        pwGroups.removeClass('pw-focused')
            .find('.toggle-password').text('Show')
            .prev('input').attr('type','password');
    }

    function passShow(toggleBTN) {
        var pwField = toggleBTN.prev('input');
        if (pwField.attr('type') == 'password') {
            pwField.attr('type', 'text')
            toggleBTN.text('Hide');
        } else {
            pwField.attr('type', 'password')
            toggleBTN.text('Show');
        };
    }


    // FORMS - Validation

    $('.form-control[required]').each(function(i) {
        var myLabel = $(this).attr('id');
        $('label[for="' + myLabel + '"]').addClass('req-label');
    });

    $('label.required-label').prepend('* ');

    // Adds the error state to the .form-group when detected from server
    $('.form-group').has('.inline_error').addClass('has-error');

    // Removes the error state and the message
    $('.has-error .form-control').click(function() {
        clearGroupError($(this));
    }).focus(function() {
        $(this).keydown(function() {
            clearGroupError($(this));
        });
    })

    $('.cbr-combo').each(function(i) {
        var comboCheck = $(this).find('input[type="checkbox"]');
        var comboText = $(this).find('input.form-control');
        var checkFor = comboCheck.attr('id');
        var errored = $(this).closest('.form-group').find('.inline_error').length;

        $(this).find('label').attr('for', checkFor);

        comboCheck.change(function(e) {
            if ($(this).is(':checked')) {
                comboText.val('');
            }

            if (errored) {
                clearGroupError($(this));
            };
        });

        comboText.keydown(function(e) {
            if (comboCheck.is(':checked')) {
                comboCheck.prop('checked', false)
            }
        });
    });



    function clearGroupError(formEl) {
        formEl.closest('.form-group').removeClass('has-error').find('.inline_error').remove();
    }


    // FORMS - Switch submit button to "Processing" state
    // To prevent "hammering" and provide feedback to the user
    var submitted = false;

    $('.btn-oneclick').click(function(event) {
        submitted = true;
    }).closest('form').submit(function() {
        if (submitted) {
            // event.preventDefault();
            setTimeout(function() {
                $('.btn-oneclick').delay(8000).css('display','none').after(processing_button);            
            }, 500)
        }
    });



    // FORMS - Switch checkbox to radio button
    // Any checkbox placed within a .radio container will be changed

    $('.radio input[type="checkbox"]').each(function() {
        $(this).attr('type', 'radio');
    })

    // Depricated
    // if ($('.num-date-masked').length != 0) {
    //     $('.num-date-masked').attr('placeholder', 'MM/DD/YYYY').mask('99/99/9999', {
    //         completed: function() {
    //             $(this).attr('data-valid',true);
    //         }
    //     })
    // }

    $('.num-date-masked').each(function(i) {
        var dates = [];

        dates[i] = new Cleave($(this), {
            date: true,
            datePattern: ['m', 'd', 'Y'],
            placeholder: 'MM/DD/YYYY'
        }); 
        
        $('.num-date-masked').attr('placeholder','MM/DD/YYYY')
    });

    $('.soft-date').each(function(i) {
        var dates = [];

        dates[i] = new Cleave($(this), {
            date: true,
            datePattern: ['m', 'Y'],
            placeholder: 'Date'
        }); 
        
        $('.soft-date').attr('placeholder','MM/YYYY')
    });

    $('.h5-tel input').each(function(i) {
        var phones = [];

        phones[i] = new Cleave($(this), {
            numericOnly: true,
            blocks: [0, 3, 3, 4],
            delimiters: ['(', ') ', '-']
        }); 
        
        $('.h5-tel input').attr('placeholder','(000) 000-0000 ')
    });

    $('.form-group[data-mask]').each(function(i) {
        var maskType = $(this).data('mask');
        var myField = $(this).find('.form-control');
        var cleaver = [];
        var placeholder;

        switch(true) {
         case   maskType == 'date':
                cleaver[i] = new Cleave(myField, {
                    date: true,
                    datePattern: ['m', 'd', 'Y']
                });
                placeholder = 'MM/DD/YYYY';
          break;
         case   maskType == 'usd':
                cleaver[i] = new Cleave(myField, {
                    numeral: true,
                    numeralDecimalScale: 2,
                    prefix: '$',
                    signBeforePrefix: true,
                    noImmediatePrefix: true
                });
                placeholder = '$0.00';
          break;
         case   maskType == 'ssn-four':
                cleaver[i] = new Cleave(myField, {
                    numericOnly: true,
                    blocks: [4]
                });
                placeholder = '0000';
          break;
         case   maskType == 'ssn-full':
                cleaver[i] = new Cleave(myField, {
                    numericOnly: true,
                    blocks: [3, 2, 4],
                    delimiters: ['-']
                });
                placeholder = '000-00-0000';
          break;
         case   maskType == 'pct':
                cleaver[i] = new Cleave(myField, {
                    numericOnly: true,
                    blocks: [3]
                });
                placeholder = '---';
          break;
         default:
              // code to be executed if n is different from case 1 and 2
        }

        if (placeholder != '') {
            myField.attr('placeholder', placeholder);
        }
        
    });

    var pctsym = $('<span/>', {
        'class': 'input-group-addon',
        'text': '%'        
    })

    $('[data-mask="pct"] .input-group').append(pctsym);




    // FORMS - Radio button table behavior
    // Basic function (sledgehammer approach).
    // When a radio button in a table row is selected, ALL OTHER
    // radio buttons on the page are deselected. 

    // This may not be applicable for ALL SCENARIOS going forward,
    // but it works for all current ones.


    $('.table-selector .checkbox input, .table-selector .radio input').click(function(event) {
        var theTable = ($(this).closest('.table-selector'));
        var isRadio = $(event.target).attr('type') == 'radio';
        var controlButtons = findTableControls(theTable);

        tableChecker(theTable);


        if (controlButtons != null) {
            var activateButtons = $('.table-selector tbody').has('input:checked').length != 0;

            dependentButtons(controlButtons, activateButtons);

            if (isRadio) {
                $('.table-controls .selection-dependent, .btn-holder-md .selection-dependent').not(controlButtons).each(function(){
                    dependentButtons($(this), false);
                })
            }
        }

    })

    $('.table-selector').each(function() {
        var theTable = $(this);
        var controlButtons = findTableControls(theTable);

        if ($(this).has('input[type="checkbox"]:selected,input[type="radio"]:selected').length == 0) {

            if (controlButtons != null) {
                controlButtons.each( function(){
                    var btnState;

                    btnClasses = $(this).attr('class');
                    switch(true) {
                        case    btnClasses.indexOf('btn-primary') != -1:
                                var btnState = 'primary';
                                break;
                        case    btnClasses.indexOf('btn-info') != -1:
                                var btnState = 'info';
                                break;
                        case    btnClasses.indexOf('btn-warning') != -1:
                                var btnState = 'warning';
                                break;
                        case    btnClasses.indexOf('btn-danger') != -1:
                                var btnState = 'danger';
                                break;
                        case    btnClasses.indexOf('btn-success') != -1:
                                var btnState = 'success';
                                break;
                        default:
                                var btnState = 'default';
                    };
                        $(this).attr('data-state',btnState);
                        $(this).removeClass('btn-' + btnState)
                    })
                    .addClass('disabled').attr('disabled', 'disabled');
            }
        }
    });


    $('.table-radio input[type="radio"]').click(function() {

        //  Deselects all other radio buttons on page
        $('input[type="radio"]').not($(this)).prop('checked', false);

        // var selected_table = ($(this).closest('.table-radio'));
        tableChecker('.table-radio');
    })

    $('.table-check-me input[type="checkbox"]').click(function() {

        //  Deselects all other checkboxes on page
        $('.table-check-me input[type="checkbox"]').not($(this)).each(function() {
            $(this).prop('checked', false);
            var the_table = $(this).closest('.table-selector');
            tableChecker(the_table);
        });
    });


    $('.table-message').each( function(i){
            var theTable   = $(this).prev('table').find('thead');
            var colCount = $(this).prev('table').find('tbody tr:first-child td').length
            var msgContent = $(this).html().trim();
            var msgState   = $(this).attr('data-state');
            var j = i + 1;

            $(this).remove();

            theTable.addClass('has-msg');

            $('<tr></tr>', {
                     id: 'message0' + j,
                'class': 'tbl-msg ' + msgState
            }).prependTo(theTable);

            $('<th></th>', {
                   html: msgContent
            })
                .attr('colspan', colCount)
                .each(function() {
                    leadIconAlert($(this));                
                })
                .prependTo( '#message0' + j);
    });


    // FORMS - Character field limit
    // Creates a Bootstrap "popover" bubble for designated fields
    // http://getbootstrap.com/javascript/#popovers

    // 1. Apply .char-limit to surrounding div (usually ".form-group" )
    // 2. Be sure to give the field a "maxlength" attribute
    // 3. Popover will be dismissed (hidden) upon another keystroke(keyup) or
    //    if the field is blurred

    $('.char-limit').keyup(function() {
        // var theLabel = $(this).find('.control-label').text();
        // theLabel = theLabel.replace(/[^a-z0-9\s]/gi, '');
        var theField = $(this).find('.form-control');
        var charMax = theField.attr('maxlength');
        var charCount = theField.val().length;

        theField.popover({
            title: "Sorry",
            content: "At this time, our system imposes a " + charMax + "-character limit on this field.",
            trigger: 'manual',
            placement: 'top'
        })

        if (charCount == charMax) {
            theField.popover('show').blur(function() {
                $(this).popover('hide');
            })
        } else {
            theField.popover('hide');
        }
    })

    $('.text-count textarea').each(function(i) {
        var myLabel = $(this).attr('id');
        $(this).closest('.text-count').find('.control-label').attr('for', myLabel);

        updateTextCount($(this));
        this.value = this.value.replace(/(\r\n|\n|\r)/gm, ' ');
    }).on('input', function(e){
        // Replaces smart single-quotes, apostrophe
        this.value = this.value.replace(/[\u2018\u2019\u201A]/g, '\'');
        // Replaces smart double-quotes
        this.value = this.value.replace(/[\u201C\u201D\u201E]/g, '"');
        // Replaces ellipses character
        this.value = this.value.replace(/\u2026/g, '...');
        // Replaces en- and em-dashes
        this.value = this.value.replace(/[\u2013\u2014]/g, '-');
        // Replaces MS Word spaces
        this.value = this.value.replace(/[\u02DC\u00A0]/g, ' ');
        // Replaces carriage return with space
        this.value = this.value.replace(/(\r\n|\n|\r)/gm, ' ');
        // Eliminates any additional characters not specified 
        $(this).val($(this).val().replace(/[^a-z0-9.,+=!@#$%^&*(){}<>?/,."'+-= \/\\]/gi, ''));
        updateTextCount($(this));
    });




    if (($('.process-map').length == 1) && ($('h1').length == 0)) {
        $('legend').addClass('legend-head')
    }

    // FORMS - Foreign address toggle
    // Applies/Removes .foriegn to the .address-form fieldset

    $('#intAddr').click(function() {
        if ($(this).is(':checked')) {
            $('.address-form').addClass('foreign')
        } else {
            $('.address-form').removeClass('foreign')
        }
    })


});

$('#evcert-btn-modal').click( function(event){
    $('#evcertModal').modal('show');
})

function updateTextCount(msg) {
    var textLimit = msg.closest('.text-count').data('charlimit');
    var remaining = textLimit - msg.val().length;
    var msgStatus = msg.next('.text-count-status');

    var msgMax =  $('<span/>', {
                        text: 'Maximum limit reached: ',
                        class: 'maxed'
                    });
    msg.attr('maxlength', textLimit);

    

    if (remaining == 0) {
        if (!msgStatus.hasClass('alert-danger')) {
            msgStatus.addClass('alert-danger').prepend(msgMax);
        }
    } else if (msgStatus.hasClass('alert-danger')) {
        msgStatus.removeClass('alert-danger').find('.maxed').remove();
    }

    if ((remaining < 11) && (remaining != 0)) {
        msgStatus.addClass('alert-warning');
    } else if (msgStatus.hasClass('alert-warning')) {
        msgStatus.removeClass('alert-warning');
    }

    msg.next('.text-count-status').find('.countdown').text(remaining);
}

if ($('.card__gallery').length != 0) {
    $('.card__gallery').each(function(i) {
        var cardcount = $(this).find('.card').length;
        $(this).attr('data-cards', cardcount);        
    });
}



// Adds a message to an empty element
function nullValue(el, msg) {
    if (trimVal(el) == '') {
        el.html('<em>' + msg + '<em>')
    }
}

// Trims the extra spacing from a text string
function trimVal(trimit) {
    var trimmed = trimit.text().trim();
    return trimmed;
}


// must load plugin jquery-color BEFORE main.js
function fadeHighlight(elem, timeDelay){
    timeDelay = timeDelay * 1000;
    elem.addClass('relevant').animate({
        backgroundColor: '#fffeaf'
    }, 500, function(){
        $(this).delay(timeDelay).animate({
        backgroundColor: 'transparent'
    }, 2000 )
    } );
}

function printModalLoad() {
    $('#print-content').html(myContent);
}

function insertPlaceholder(theInput, theValue) {
    theInput.attr('placeholder', theValue)
}


function setAriaTable(theTable) {
    theTable.each(function(){
        $(this).attr('role','grid');
        $(this).find('tr').attr('role','row');
        $(this).find('thead th').attr('role','columnheader');
        $(this).find('tbody td').attr('role','gridcell');
        $(this).find('tfoot td').attr('role','gridcell');
    })
}

function tableChecker(theTable) {
    $(theTable).find('tr').each(function(i) {
        if ($(this).has('input:checked').length != 0) {
            $(this).addClass('selected')
        } else {
            $(this).removeClass('selected')
        }
    })
}

function findTableControls(theTable, controlButtons) {

    if (theTable.prev('.table-controls').has('.selection-dependent').length == 1) {
        hasControls = true;
        controlButtons = theTable.prev('.table-controls').find('.selection-dependent');
        theTable.attr('data-controlBtns','leading');
    } else if (theTable.next('.btn-holder-md').find('.selection-dependent').length == 1) {
        hasControls = true;
        theTable.attr('data-controlBtns','trailing');
        controlButtons = theTable.next('.btn-holder-md').find('.selection-dependent');
    } else {

        controlButtons = null;
    }

    return controlButtons;
}

function leadIconAlert(theEl) {
    theEl.find('*:first-child > .fa:first-child').each(function() {
        theEl.addClass('lead-icon');
    });
}

function dependentButtons(theControlButtons, activate) {
    if (activate) {
        theControlButtons.each( function(){
            var btnState = $(this).attr('data-state');
            $(this).addClass('btn-' + btnState)
        })
        .removeClass('disabled')
        .removeAttr('disabled')
    } else {
        theControlButtons.each( function(){
            var btnState = $(this).attr('data-state');
            $(this).removeClass('btn-' + btnState)
        })
        .addClass('disabled')
        .attr('disabled', 'disabled');
    }
}

function activateProcessMap(stepName) {
    var numSteps = $('.process-map ul li, .process-map ol li').length;
    var whichStep = 0;
    var processText = "Steps for process";

    $('.process-map ul li, .process-map ol li').each(function() {
        if ($(this).text() == stepName) {
            $(this)
                .addClass('active')
                .append('<span class="sr-only"> (You Are Here)</span>')
                .prevAll('li')
                .addClass('completed');
            whichStep = $(this).index() + 1;
            processText = 'Step ' + whichStep + ' of ' + numSteps + ' steps'
        }
    });

    $('.process-map').closest('#process-overview')
        .siblings('.page-header').addClass('with-proc-count')
        .find('h1').append('<small class="sub-head proc-count">' + processText + "</small>");

    $('#nav-skip').before('<a href="#process-overview" class="sr-only">Skip to list of steps</a>')
}

function hiliteMenu(menuId) {
    element = document.getElementById(menuId);
    if (element != null) {
        element.className = 'gnselect';
    }
}

/* You can call this from inside a page to eliminate double submits from button-banging */
var submitted = false;

function checkSubmit() {

    if (submitted == false) {
        submitted = true;
        return true;
    } else {
        return false;
    }

}


// FORMS - For attribute
// form-group in standard Horizontal form
function getForHz(){
    $('.control-label').each(function() {
        var myLabel = $(this).next('div').find('.form-control').attr('id');
        $(this).attr('for', myLabel);
    });
}

// $('.form-group.horizontal').each(function() {
//     var myLabel = $(this).find('.form-control').attr('id');
//     $(this).find('.control-label').attr('for', myLabel);
// });



$('.control-label').each(function(i) {
    var myFor = $(this).closest('.form-group')
        .find('.form-control').attr('id');
    $(this).attr('for', myFor); 
});



// Checkboxes/Radio Button "for" attribute
function cbrFor() {
    $('.checkbox').each(function(i) {
        var myFor = $(this).find('input').attr('id');
        $(this).find('label').attr('for', myFor);
    });
}


function check2rad() {
    $('*[data-c2r]').each(function(i) {
        var newName = $(this).attr('data-c2r');
        $(this).find('input[type="checkbox"').each(function(i) {
            $(this).attr({
                type: 'radio',
                name: newName
            });
        });
    });
}

// $('.field-flex').each(function(i) {
//     var myFor = $(this).find('input').attr('id'); 
//     if (myFor == null) {
//         myFor = 'inp_0' + (i + 1);
//         $(this).find('input').attr('id', myFor);
//     }

//     $(this).find('label').attr('for', myFor);
// });

// $('.address-form .form-group').each(function(i) {
//     var myFor = $(this).find('.form-control').attr('id');
//     if (myFor == "") {
//         myFor = 'addr_0' + (i + 1);
//         $(this).find('.form-control').attr('id', myFor);
//     };
//     $(this).find('label').attr('for', myFor);
// });

// Disability HTML Prototype functions
// function setPageLabel(task) {
//     $('.process-label strong[data-pagelabel]').each(function(i) {
//         if ($(this).data('pagelabel') != task) {
//             $(this).remove();
//         }
//     });
// }


// Initialize Toast message element
function toastInit(msgtext, timing, state) {

    var msg = $('<p/>', {
        'class': 'msg-text',
        'text': msgtext
    });

    $('.toast').prepend(msg).attr({
        'data-state': state,
        'data-init': true,
        'role': 'alert'
    }).removeAttr('aria-hidden');

    setTimeout(function() {
        $('html').bind('click', function() {
          toastExit();
        });
    }, 50);

    setTimeout(function() {
        toastExit();
    }, timing);
}

// Allow clicking Toast message element
// without it closing
$('.toast').click(function(e) {
    e.stopPropagation();
});

// Close Toast message element
function toastExit() {
    $('.toast').attr({
        'data-init': false,
        'aria-hidden': true
    }).removeAttr('role');
    $('html').unbind('click');

    setTimeout(function() {
        $('.toast .msg-text').remove();
    }, 1000);
}

